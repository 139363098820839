<template>
  <div>
    <div class="card-panel">
      <div class="form-row">
        <div class="col-12">
          <div class="row">
            <p class="title nav-tabs col-8">
              <b>Identificação Fornecedor</b>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="mb-2 col-12 d-flex flex-row justify-content-between">
        <input id="lmxta-cadastropessoa-btn-sair" type="button" value="Voltar" class="btn btn-default" @click="btnSair_Click" />
        <input
          id="lmxta-cadastropessoa-btn-salvar"
          type="button"
          value="Salvar"
          class="btn btn-primary"
          @click="btnSalvar_Click"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import swal from '@/common/alerts';
  import loading from '@/common/loading';
  import { mapGetters } from 'vuex';
  import router from '@/router';

  export default {
    name: 'DevolucaoFornecedor',

    methods: {
      btnSair_Click() {
        router.push({ name: 'DevolucaoIdentificacao' });
      },

      btnSalvar_Click() {
        swal.exibirMensagemSucesso('Hello Cadastro Fornecedor!!');
      },
    },

    mounted() {
      loading.exibir();

      setTimeout(() => {
        loading.ocultar();
      }, 500);
    },
  };
</script>
